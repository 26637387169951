<script setup>
import Icon from '@/Components/Icon/index.vue';
import Button from '@/Components/Button.vue';
import { computed } from 'vue';
import ListItem from '@/Components/List/Item/index.vue';
import { Link } from '@inertiajs/vue3';
const props = defineProps({
  course: {
    type: Object,
    required: true,
  },
  tuitionBracket: {
    type: Object,
    required: false
  },
});

const courseSite = computed(() => {
  if(!props.course.sites.length){
    return null;
  }

  if(props.tuitionBracket) {
    const site = props.course.sites.find((site) => 
      site.fees.overseas_full_fee && 
      site.fees.overseas_full_fee > props.tuitionBracket.min && 
      site.fees.overseas_full_fee < props.tuitionBracket.max
    );

    return site
  }
  
  return props.course.sites[0];
  
});

const duration = computed(() => {
  if(courseSite.value && courseSite.value.attendance && courseSite.value.attendance.length){
    const length = courseSite.value.attendance[0]["length"];
    const period = courseSite.value.attendance[0].du_name;
    const mode = courseSite.value.attendance[0].name;
  
    return `${length} ${period} ${mode}`;
  }
  return null;
})

const tuition = computed(() => {

  if(courseSite.value && courseSite.value.fees && courseSite.value.fees.overseas_full_fee) {
    const formatter = new Intl.NumberFormat('en-AU', {
      currency: 'AUD',
    });
    return `${formatter.format(courseSite.value.fees.overseas_full_fee)}`
  }
  return null;
})

const startDate = computed(() => {
  if(courseSite.value && courseSite.value.course_start_date) {
    return `${courseSite.value.course_start_date}`
  }
  return null;
})

</script>

<template>
        <ListItem :image-set="course.organisation.logos" :alt-text="course.organisation.name" fallback-icon="Book" :logo-link="`/provider/${course.organisation.slug}/${course.organisation.id}`">
          <template #title>
            <Link
                :href="`/course/${course.slug}/${course.id}`"
                class="border-none"
              >{{ course.name }}</Link>
          </template>
          <template #subtitle>
            <Link
            :href="`/provider/${course.organisation.slug}/${course.organisation.id}`"
            :aria-label="`Course provider ${course.organisation.name}`"
            class="border-none mb-2 font-bold"
          >{{ course.organisation.name }}</Link>
          </template>

          <template #content>
            <p
          v-if="courseSite"
          class="text-sm flex align-items-center mb-2"
        >
          <Icon
            class="me-2"
            path="University"
            :height="16"
            :width="16"
          />
          {{ courseSite.region.name }} 
          <a
            v-if="course.sites.length && course.sites.length > 1"
            tabindex="-1"
            class="ms-2 text-sm"
            :href="`/course/${course.slug}/${course.id}`"
          >+ {{ course.sites.length - 1 }} other campuses</a>
        </p>
       
        <p v-if="duration" class="text-sm mb-2 flex align-items-center"><Icon
class="me-2" path="Clock" 
            :height="16"
            :width="16"/>Duration: {{ duration }}</p>
        <p v-if="tuition" class="text-sm mb-2 flex align-items-center"><Icon
class="me-2" path="DollarSquare" 
            :height="16"
            :width="16"/>Estimated total course cost: ${{ tuition }}</p>
        <p v-if="startDate" class="text-sm mb-2 flex align-items-center"><Icon
class="me-2" path="Calendar" 
            :height="16"
            :width="16"/>Start date: {{ startDate }}</p>
          </template>

          <template #action1>
            <Button
              :aria-label="`Enquire for ${course.name}`"
              type="link"
              :link="`/course/${course.slug}/${course.id}/enquiry`"
              class="w-full md:w-fit"
            >
              Enquire
            </Button>
          </template>
          <template #action2>
            <Button
              variant="link"
              type="link"
              :aria-label="`Learn more about ${course.name}`"
              :link="`/course/${course.slug}/${course.id}`"
              class="w-full md:w-fit "
            >
              Learn More
            </Button>
          </template>
            
        </ListItem>
        
</template>
